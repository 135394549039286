// eslint-disable
export default {
  addons: 'Dodaci',
  about: 'O nama',
  calories: 'Kalorije',
  currency: 'MKD',
  allergens: 'Alergeni',
  addToCart: 'Dodaj u košaricu',
  addedToCart: 'Dodano u košaricu',
  menu: 'Meni',
  // TODO: Translate
  menuTitle: 'Tap to order!',
  // TODO: Translate
  menuTitleDescription: '',
  in: 'u',
  name: 'Ime',
  status: 'Status',
  dialogContentText: 'Pozor: nakon što je račun, narudžba više neće biti dostupna',
  dialogTitle: 'Potvrda je potrebna prije zatvaranja narudžbe',
  food: 'Hrana',
  submit: 'Potvrdi',
  description: 'Opis',
  table: 'Stol',
  drinks: 'Pića',
  drink: 'Piće',
  active: 'Aktivno',
  // TODO: Translate
  orderStateCancelled: 'cancelled',
  // TODO: Translate
  orderStateDelivered: 'delivered',
  // TODO: Translate
  orderStateDeliveredSelfService: 'ready',
  // TODO: Translate
  orderStateCreated: 'created',
  // TODO: Translate
  orderStatePreparation: 'preparation',
  // TODO: Translate
  reset: 'Reset',
  type: 'Vrsta',
  edit: 'Uredi',
  cancel: 'Odustani',
  quantity: 'Količina',
  price: 'Cijena',
  subType: 'Podvrsta',
  save: 'Spremi',
  accept: 'Prihvati',
  logOut: 'Odjava',
  delete: 'Izbriši',
  language: 'Jezik',
  inactive: 'Neaktivno',
  orderId: 'ID narudžbe',
  createdAt: 'Kreirano',
  // TODO: translate
  readonlyProduct: 'Call a waiter to order this item',
  // TODO: translate
  orderNow: 'Order now',
  orders: 'Narudžbe',
  noOrdersAtTheMoment: 'Trenutno nema narudžbi :(',
  // TODO: Translate
  adminProductStatusViewOnly: 'View-only',
  // TODO: Translate
  adminProductStatusesMessage:
    'Watch out, this will prevent your customers from ordering any of the products?',
  // TODO: Translate
  adminAddNewVariant: 'Add new variant',
  // TODO: Translate
  adminConfirmCancellingOrder: 'Are you sure you wanna cancel this order?',
  // TODO: translate
  adminRestaurantInfoEditPageCloseRestaurant: 'Close restaurant',
  // TODO: translate
  adminRestaurantInfoEditPageOpenRestaurant: 'Open restaurant',
  // TODO: translate
  adminRestaurantClosedTitle: 'The restaurant is closed!',
  // TODO: translate
  adminRestaurantClosedDescription: 'Open the restaurant to accept orders',
  // TODO: translate
  adminRestaurantIsSelfService: 'This restaurant is self-service',
  adminWaiterCalled: 'Pozvan konobar!',
  adminReceiptCalled: 'Pozvan račun!',
  adminAcceptedClientCallNotification: 'Prihvatili ste poziv klijenta',
  adminAcceptedChangeOrderStatusNotification: 'Promijenili ste status narudžbe u prihvaćeno',
  // TODO: translate
  noActiveTables: 'No active tables found',
  noResults: 'Nema rezultata',
  userAskedForReceiptNotification: 'Zatražili ste račun. Vaše narudžbe su zatvorene!',
  // TODO: translate
  userCalledWaiterCall: 'Waiter has received your request. Hold on until your request is accepted',
  userAcceptedWaiterCall: 'Vaš poziv konobara je prihvaćen',
  userWaiterBringReceipt: 'Vaš konobar će donijeti račun!',
  userOrderAccepted: 'Narudžba br. {{clientsOrderId}} je prihvaćena',
  userOrderAcceptedStatus: 'Status: {{clientsOrderStatus}}',
  // TODO: translate
  userOrderDelivered: 'Order No.{{clientsOrderId}} is Ready!',
  userTableNumberCode: 'Vaš stol ({{tableNumber}}) kod je:',
  redirectEnterCode: 'Vaš stol je zaključan! Molimo unesite kod:',
  redirectOpenWithCode: 'Otvorite stol s kodom!',
  redirectEnterValidCode: 'Molimo unesite ispravan kod!',
  redirectYourCodeIs: 'Vaš kod je {{code}}',
  redirectOpenTable: 'Otvori!',
  // TODO: Translate
  restaurantClosed: 'The restaurant is not accepting orders at the moment. Please try again later.',
  alertAgree: 'Slažem se',
  alertDisagree: 'Ne slažem se',
  // TODO: Translate
  cart: 'Cart',
  // TODO: Translate
  cartAllOrders: 'All orders ({{tableNumber}})',
  cartOrder: 'Naruči',
  cartItems: 'Stavke u košarici',
  cartClearAllButton: 'Isprazni sve',
  cartNoItems: 'Nema stavki u košarici',
  cartAdditionalComment: 'Additional Comments for order',
  adminAdditionalComment: 'Comments for order',
  cartTotal: 'Ukupno',
  cartCallWaiterButton: 'Pozovi konobara',
  cartOrderButton: 'Naruči',
  cartCloseButton: 'Zatvori',
  receiptTotalAmount: 'Ukupan iznos za plaćanje: {{totalAmount}}',
  tableOrders: 'Narudžbe',
  tableOrdersTotalAmount: 'Ukupan iznos: {{totalAmount}}',
  tableOrdersAskForReceipt: 'Zatraži račun',
  tableOrdersOrderItems: 'Stavke narudžbe',
  tableOrdersNoItems: 'Nema stavki u košarici',
  landingPageTitle: 'Dobrodošli na AnyMenu',
  landingPageSubTitle: 'Odaberite restoran za početak.',
  landingPageRestaurants: 'Odaberite restoran za početak.',
  landingPageErrorLoadRestaurants: 'Došlo je do pogreške prilikom dohvaćanja restorana.',
  landingPageNoRestaurantsAvailable: 'Trenutno nema dostupnih restorana.',
  errorPageDesc: 'Žao nam je, stranica nije pronađena.',
  errorPageButtonBack: 'Natrag',
  loginPageTitle: 'Dobrodošli natrag',
  adminSiteNavHome: 'Početna',
  adminSiteNavRestaurant: 'Restoran',
  adminSiteNavProducts: 'Proizvodi',
  adminSiteNavOrders: 'Narudžbe',
  adminSiteNavManageUsers: 'Upravljanje korisnicima',
  // TODO: translate
  adminSiteNavManageApiKeys: 'Manage API Keys',
  // TODO: translate
  adminApiKeyCreated: 'A new API key has been created',
  adminManageUserCreated: 'Korisnik je kreiran!',
  adminManageUserDeleted: 'Korisnik je izbrisan!',
  adminManageUserUpdated: 'Korisnik je ažuriran',
  // TODO: Translate
  adminMarkAsDelivered: 'Mark as delivered',
  adminTypeOfUser: 'Tip korisnika',
  adminUserChangePassword: 'Promijeni lozinku',
  adminUserChangePasswordNewLabel: 'Nova lozinka',
  adminUserChangePasswordVerifyLabel: 'Potvrdi lozinku',
  landingPageEnterTitle: 'Otvori!',
  typeOfUser: 'Tip: ',
  adminSiteNavManageRestaurant: 'Upravljanje restoranima',
  // TODO: Translate
  adminPushNotificationOrderTitle: 'New order received!',
  // TODO: Translate
  adminPushNotificationOrderBody: '`Table {{tableNumber}} has a new order with ID: {{order_id}}',
  // TODO: Translate
  adminPushNotificationCallWaiterTitle: 'Waiter call!',
  // TODO: Translate
  adminPushNotificationCallWaiterBody: 'Table {{tableNumber}} called for waiter',
  // TODO: Translate
  adminPushNotificationCallReceiptTitle: 'Receipt request!',
  // TODO: Translate
  adminPushNotificationCallReceiptBody: 'Table {{tableNumber}} has asked for receipt',
  // TODO translate
  adminNewOrdersBadge: '{{count}} New Orders',
  adminManageRestaurantPageTitle: 'Upravljanje restoranima',
  adminManageRestaurantTitle: 'Restorani',
  adminFieldsManageRestaurantEmail: 'Email',
  adminFieldsManageRestaurantAddress: 'Adresa',
  adminFieldsManageRestaurantPhone: 'Telefonski broj',
  adminFieldsManageRestaurantWifi: 'WiFi',
  adminFieldsManageRestaurantInstagram: 'Instagram',
  adminManageRestaurantEdit: 'Restoran je ažuriran',
  adminManageRestaurantCreate: 'Restoran je kreiran!',
  adminManageRestaurantDeleted: 'Restoran je izbrisan!',
  adminSelectresourant: 'Izberi restoran',
  adminTableFiltersButton: 'Kreiraj',
  adminCreateQRCodeShowCodeButton: 'Prikaži QR kodove stolova',
  adminCreateQRCodeDialogTitle: 'Informacije o QR kodovima stolova',
  // TODO: Translate
  adminCreateQRCodeDownloadAll: 'Download All for Print',
  adminCreateQRCodeDownload: 'Preuzmi za ispis',
  // TODO: Translate
  adminSimulateQRCodeLink: 'Simulate scan (opens in a new tab)',
  adminRestaurantInfoEditButton: 'Uredi',
  adminRestaurantInfoEditTitle: 'Uredi informacije',
  adminRestaurantInfoEditTablesButton: 'Uredi stolove',
  adminRestaurantInfoEditTablesTitle: 'Uredi informacije',
  adminRestaurantInfoEditTablesInfo: 'Broj stola',
  adminRestaurantInfoEditTablesCode: 'Trenutni kod',
  adminRestaurantInfoEditPageTitle: 'Informacije o restoranu',
  adminManageUsersPageTitle: 'Manage Users',
  adminRestaurantManagement: 'Upravljanje restoranom',
  adminDashboardTitle: 'Pregled stolova',
  adminTableOverviewTitle: 'Pregled za stol',
  adminTableOverviewCloseTableBringReceipt: 'Zatvori stol i donesi račun',
  adminTableOverviewAcceptOrdersButton: 'Prihvati sve narudžbe',
  adminTableOverviewMarkAllButton: 'Označi sve kao dostavljeno',
  adminTableOverviewAllOrdersAcceptedNotification: 'Sve narudžbe su prihvaćene!',
  adminTableOverviewAllOrdersMarkedAsDeliveredNotification:
    'Označili ste sve narudžbe kao dostavljene!',
  adminTableOverviewAcceptReceiptButton: 'Prihvati poziv za račun',
  adminTableOverviewAcceptOrderButton: 'Prihvati narudžbu',
  // TODO: Translate
  adminTableOverviewCancelOrderButton: 'Cancel Order',
  adminTableOverviewSaveStatustoOrder: 'Spremi status za narudžbu',
  adminTableOverviewReceiptCalled:
    'Pozvan je račun za stol. Ako prihvatite poziv za račun, sve narudžbe će biti zatvorene za taj stol!',
  adminTableOverviewTableNumber: 'Broj stola'
};

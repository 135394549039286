// eslint-disable
export default {
  addons: 'Додатоци',
  about: 'За нас',
  calories: 'Калории',
  currency: 'МКД',
  allergens: 'Алергени',
  addToCart: 'Додади во кошничка',
  addedToCart: 'Додадено во кошничка',
  menu: 'Мени',
  menuTitle: 'Кликни и нарачај!',
  // TODO: Translate
  menuTitleDescription: '',
  in: 'во',
  name: 'Име',
  status: 'Статус',
  dialogContentText: 'Внимание: промени во нарачката не се возможни по затворање на сметката ',
  dialogTitle: 'Потврди пред да ја затвориш нарачката',
  food: 'Храна',
  submit: 'Потврди',
  description: 'Опис',
  table: 'Маса',
  drinks: 'Пијалоци',
  drink: 'Пијалок',
  active: 'Активно',
  orderNow: 'Нарачај',
  orderStateCancelled: 'Откажи',
  orderStateDelivered: 'Доставена',
  orderStateDeliveredSelfService: 'Спремна',
  orderStateCreated: 'Креирана',
  orderStatePreparation: 'Во припрема',
  reset: 'Ресетирај',
  type: 'Тип',
  edit: 'Уреди',
  cancel: 'Откажи',
  quantity: 'Количина',
  price: 'Цена',
  subType: 'Подтип',
  save: 'Зачувај',
  accept: 'Прифати',
  logOut: 'Одјави се',
  delete: 'Избриши',
  language: 'Јазик',
  inactive: 'Неактивно',
  orderId: 'Број на нарачка',
  createdAt: 'Создадено на',
  orders: 'Нарачки',
  noOrdersAtTheMoment: 'Моментално нема нарачки :(',
  readonlyProduct: 'Повикај келнер за да го нарачаш овој продукт',
  adminProductStatusViewOnly: 'Преглед',
  adminProductStatusesMessage:
    'Внимание: муштериите нема да можат на прават нарачки за овие продукти преку апликацијата',
  adminAddNewVariant: 'Додади нова варијанта',
  adminConfirmCancellingOrder: 'Дали си сигурен/на дека сакаш да ја откажеш нарачката?',
  adminRestaurantInfoEditPageCloseRestaurant: 'Затвори го локалот',
  adminRestaurantClosedTitle: 'Локалот е затворен!',
  adminRestaurantClosedDescription: 'Отвори го локалот и започни со нарачки',
  adminRestaurantIsSelfService: 'Овој локал работи со самопослуга',
  adminRestaurantInfoEditPageOpenRestaurant: 'Отвори го локалот',
  adminWaiterCalled: 'Келнерот/ката е повикан/а!',
  adminReceiptCalled: 'Сметката е побарана!',
  adminAcceptedClientCallNotification: 'Го прифативте повикот на клиентот',
  adminAcceptedChangeOrderStatusNotification: 'Ја променивте состојбата на нарачката во прифатена',
  noActiveTables: 'Не се пронајдени активни маси',
  noResults: 'Не се пронајдени резултати',
  userAskedForReceiptNotification: 'Побаравте сметка. Вашите нарачки се затворени!',
  userCalledWaiterCall: 'Келнер/ката е повикан/а. Ве молиме почекајте.',
  userAcceptedWaiterCall: 'Вашето барање за келнер/ката е прифатено',
  userWaiterBringReceipt: 'Вашиот келнер ја носи сметката!',
  userOrderAccepted: 'Нарачка бр.{{clientsOrderId}} е прифатена',
  userOrderAcceptedStatus: 'Статус: {{clientsOrderStatus}}',
  userTableNumberCode: 'Кодот за маса ({{tableNumber}}) е:',
  redirectEnterCode: 'Вашата маса е заклучена! Ве молиме внесете го кодот:',
  redirectOpenWithCode: 'Отворете ја масата со код!',
  redirectEnterValidCode: 'Ве молиме внесете валиден код!',
  redirectYourCodeIs: 'Вашиот код е {{code}}',
  redirectOpenTable: 'Отворете!',
  restaurantClosed: 'Локалот моментално не прифаќа нарачки.',
  alertAgree: 'Се согласувам',
  alertDisagree: 'Не се согласувам',
  cart: 'Кошничка',
  cartAllOrders: 'Сите нарачки ({{tableNumber}})',
  cartOrder: 'Нарачка',
  cartItems: 'Предмети во кошничката',
  cartClearAllButton: 'Испразни ја кошничката',
  cartNoItems: 'Нема предмети во кошничката',
  cartAdditionalComment: 'Дополнителни коментари за нарачката',
  adminAdditionalComment: 'Забелешки за нарачката',
  cartTotal: 'Вкупно',
  cartCallWaiterButton: 'Повикај келнер/ка',
  cartOrderButton: 'Нарачај',
  cartCloseButton: 'Затвори',
  receiptTotalAmount: 'Вкупен износ за плаќање: {{totalAmount}}',
  tableOrders: 'Нарачки',
  tableOrdersTotalAmount: 'Вкупен износ: {{totalAmount}}',
  tableOrdersAskForReceipt: 'сметка',
  tableOrdersOrderItems: 'Нарачани производи',
  tableOrdersNoItems: 'Нема предмети во кошничката',
  landingPageTitle: 'Добредојдовте на AnyMenu',
  landingPageSubTitle: 'Изберете локал',
  landingPageRestaurants: 'Изберете локал за да започнете',
  landingPageErrorLoadRestaurants: 'Се појави грешка при вчитување на локалите',
  landingPageNoRestaurantsAvailable: 'Моментално нема достапни локали',
  errorPageDesc: 'Извинете, не можевме да ја најдеме таа страна',
  errorPageButtonBack: 'Назад',
  loginPageTitle: 'Добредојдовте',
  adminSiteNavHome: 'Дома',
  adminSiteNavRestaurant: 'Ресторан',
  adminSiteNavProducts: 'Продукти',
  adminSiteNavOrders: 'Нарачки',
  adminSiteNavManageUsers: 'Уреди корисници',
  landingPageEnterTitle: 'Отвори!',
  typeOfUser: 'Тип: ',
  adminSiteNavManageRestaurant: 'Уреди локал',
  adminManageRestaurantPageTitle: 'Уреди локали',
  adminManageRestaurantTitle: 'Локали',
  adminMarkAsDelivered: 'Доставено!',
  adminPushNotificationOrderTitle: 'Нова нарачка!',
  adminPushNotificationOrderBody: '`Маса "{{tableNumber}}" има нова нарачка: {{order_id}}',
  adminPushNotificationCallWaiterTitle: 'Келнер/ка!',
  adminPushNotificationCallWaiterBody: 'Повик за келнер на маса "{{tableNumber}}"',
  adminPushNotificationCallReceiptTitle: 'Повикај сметка!',
  adminPushNotificationCallReceiptBody: 'Маса "{{tableNumber}}" побара сметка',
  adminNewOrdersBadge: '{{count}} Нови нарачки',
  adminFieldsManageRestaurantEmail: 'Е-маил',
  adminFieldsManageRestaurantAddress: 'Адреса',
  adminFieldsManageRestaurantPhone: 'Телефонски број',
  adminFieldsManageRestaurantWifi: 'WiFi',
  adminFieldsManageRestaurantInstagram: 'Instagram',
  adminManageRestaurantEdit: 'Локалот е ажуриран',
  adminManageRestaurantCreate: 'Локалот е креиран!',
  adminManageUserCreated: 'Корисникот е креиран!',
  adminManageUserDeleted: 'Корисникот е избришан!',
  adminManageUserUpdated: 'Корисникот е ажуриран',
  adminUserChangePassword: 'Промени лозинка',
  adminTypeOfUser: 'Тип на корисник',
  adminUserChangePasswordNewLabel: 'Нова лозинка',
  adminUserChangePasswordVerifyLabel: 'Потврди лозинка',
  adminManageRestaurantDeleted: 'Локалот е избришан!',
  adminSelectresourant: 'Избери локал',
  adminTableFiltersButton: 'Креирај',
  adminCreateQRCodeShowCodeButton: 'Прикажи QR кодови за масите',
  adminCreateQRCodeDialogTitle: 'Информации за QR кодовите на масите',
  adminCreateQRCodeDownload: 'Преземи за печатење',
  // TODO: Translate
  adminCreateQRCodeDownloadAll: 'Download All for Print',
  adminSimulateQRCodeLink: 'Симулирај скен на qr-code',
  adminRestaurantInfoEditButton: 'Уреди',
  adminRestaurantInfoEditTitle: 'Уреди информации',
  adminRestaurantInfoEditTablesButton: 'Уреди маси',
  adminRestaurantInfoEditTablesTitle: 'Уреди информации',
  adminRestaurantInfoEditTablesInfo: 'Број на маса',
  adminRestaurantInfoEditTablesCode: 'Код',
  adminRestaurantInfoEditPageTitle: 'Информации за локалот',
  adminManageUsersPageTitle: 'Менаџирај корисници',
  adminRestaurantManagement: 'Управување на локал',
  adminDashboardTitle: 'Преглед на масите',
  adminTableOverviewTitle: 'Преглед за масата',
  adminTableOverviewCloseTableBringReceipt: 'Затвори ја масата и донеси сметка',
  adminTableOverviewAcceptOrdersButton: 'Прифати ги сите нарачки',
  adminTableOverviewMarkAllButton: 'Означи ги сите како доставени',
  adminTableOverviewStatusUpdateNotification: 'Статусот е ажуриран',
  adminTableOverviewAllOrdersAcceptedNotification: 'Сите нарачки се прифатени!',
  adminTableOverviewAllOrdersMarkedAsDeliveredNotification:
    'Ги означивте сите нарачки како доставени!',
  adminTableOverviewAcceptReceiptButton: 'Прифати повик за сметка',
  adminTableOverviewAcceptOrderButton: 'Прифати нарачка',
  adminTableOverviewCancelOrderButton: 'Откажи ја нарачката',
  adminTableOverviewSaveStatustoOrder: 'Зачувај статус на нарачката',
  adminTableOverviewReceiptCalled:
    'Повик за фискална сметка за масата. Ако го прифатите повикот за фискална сметка, сите нарачки ќе бидат затворени за оваа маса!',
  adminTableOverviewTableNumber: 'Број на маса'
};
